import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, Checkbox } from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { useParams, useHistory } from "react-router-dom";
import {
  getSingleProduct,
  getExpiringProducts,
  getCorrelatedProducts,
} from "../service/product-service";
import LoadingScreen from "../components/layout/loading-screen";
import { addProductToCart } from "../service/cart-service";
import ProductCarousel from "../components/product-carousel";
import {
  buildExpirationMessage,
  formatPageTitle,
  stringToPrice,
} from "../service/formatting-service";
import { isLoggedIn, getUserWishlistId } from "../service/user-service";
import { path as loginPath } from "./login-page";
import { removeFromWishlist, addToWishlist } from "../service/wishlist-service";
import Config from "../config.json";
import ExpiringOffersSection from "../components/landing/finesip/expiring-offers-section";
import { useSwipeable } from "react-swipeable";
import { path as notFoundPath } from "../pages/notfound-page";
import { Helmet } from "react-helmet";

export const path = "/pages/product/:id";
export default function ProductPage({ setCloseableOnMobile, setCartItems }) {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [correlatedOffers, setCorrelatedOffers] = useState([]);
  const [expiringOffers, setExpiringOffers] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      setCurrentSlide(
        currentSlide === product?.images.length - 1 ? 0 : currentSlide + 1
      ),
    onSwipedRight: () =>
      setCurrentSlide(
        currentSlide === 0 ? product?.images.length - 1 : currentSlide - 1
      ),
  });

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => setCloseableOnMobile(false), [setCloseableOnMobile]);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);

    Promise.all([
      getSingleProduct(id),
      getCorrelatedProducts(id),
      getExpiringProducts(),
    ]).then(([productResult, correlatedOffersResult, expiringOffersResult]) => {
      if (isSubscribed) {
        if(productResult.result===false){
          history.replace(notFoundPath);
        }else{
          setProduct(productResult);
          setCorrelatedOffers(correlatedOffersResult);
          setExpiringOffers(expiringOffersResult);
          setLoading(false);
        }
      }
    });

    return () => (isSubscribed = false);
  }, [history, id]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const addToCart = async () => {
    if (!isLoggedIn()) {
      history.push(loginPath);
      return;
    }
    setLoading(true);
    const { item_count } = await addProductToCart(id);
    setCartItems(item_count);
    setLoading(false);
  };

  const attributesHtml = product.attributes.map((a, i) => (
    <div key={i} className="col-12">
      <div className="row my-2 justify-content-start text-left p-0">
        <p className="col mb-0">
          <span className="fw-600 theme-page-text">{a.name}:&nbsp;</span>
          <span>{a.options[0]}</span>
        </p>
      </div>
      <div className="row">
        <div className="col my-md-2 px-0 px-md-3">
          <hr className="theme-page-hr" />
        </div>
      </div>
    </div>
  ));

  const toggleWishlist = async () => {
    if (!isLoggedIn()) {
      history.push(loginPath);
      return;
    }
    setLoading(true);
    if (product.wishlist) {
      await removeFromWishlist(getUserWishlistId(), product.wishlist_item_id);
    } else {
      await addToWishlist(getUserWishlistId(), id);
    }
    const updatedProduct = await getSingleProduct(id);
    setProduct(updatedProduct);
    setLoading(false);
  };

  const productImages = isMobile ? (
    <div className="row mb-2 d-grid overflow-hidden" {...swipeHandlers}>
      {product.images.map((p, i) => (
        <div
          className="full-grid col left-right-animation"
          style={{ left: (i - currentSlide) * window.innerWidth }}
          key={i}
          {...swipeHandlers}
        >
          <img className="product-image img-fluid" src={p.src} alt={p.alt} />
          <Checkbox
            checked={product.wishlist}
            icon={<FavoriteBorder />}
            checkedIcon={<Favorite />}
            className="favorite-icon"
            onClick={toggleWishlist}
            color="heartCheckbox"
          />
        </div>
      ))}
    </div>
  ) : (
    product.images.map((p, i) => (
      <div className="row mb-2 d-md-flex" key={i}>
        <img className="col product-image" src={p.src} alt={p.alt} />
      </div>
    ))
  );

  const [expirationMessage, expirationClassName] = buildExpirationMessage(
    product.date_on_sale_to,
    isMobile
  );

  const additionalDescriptionsHtml = (product.apticashop_additional_fields.length>0)? (
    Object.entries(
      product.apticashop_additional_fields
    ).map(([key, value], i) => (
      <div className="col-md-6 col-12 font-14-16 mt-3 theme-page-text" key={i}>
        <h5 className="fw-600 font-14-16">{key}:</h5>
        <p>{value}</p>
      </div>
    ))
  ) : (
    <div className="col-12 font-14-16 mt-3 theme-page-text">
    <h5 className="fw-600 font-14-16">Descrizione</h5>
    <p dangerouslySetInnerHTML={{__html:product.description}}></p>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(product.name)}</title>
        <meta name="robots" content={(product.yoast_head_json.robots? product.yoast_head_json.robots.index+", "+product.yoast_head_json.robots.follow : "index, follow")} />
        <meta name="description" content={(product.yoast_head_json.description? product.yoast_head_json.description : (product.description? product.description : product.title))} />
        <meta property="og:title" content={(product.yoast_head_json.og_title)? formatPageTitle(product.yoast_head_json.og_title) : formatPageTitle(product.name)} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={(product.yoast_head_json.og_description? product.yoast_head_json.og_description : (product.description? product.description : product.title))} />
        <meta property="og:image" content={(product.yoast_head_json.og_image? product.yoast_head_json.og_image[0].url : (product.images? product.images.src : Config.projectLogo))} />
      </Helmet>
      <div className="row mt-md-5 mt-2 justify-content-center">
        <div className="col-md-5 col-12">{productImages}</div>
        <div className="col-md-5 col-12">
          <div
            className={
              "sticky-product-block row z-1 theme-page-background " +
              (isMobile ? "" : "position-sticky")
            }
          >
            <div className="col-12">
              <div className="row">
                <h3 className="col font-18-27 theme-product-title fw-600 theme-font-family">
                  {product.name}
                </h3>
              </div>
              <div className="row align-items-center d-none d-md-block">
                {(!Config.priceOnlyForLoggedIn || (Config.priceOnlyForLoggedIn && isLoggedIn())) &&  (
                  <>
                    {
                      (product.sale_price === "")? (
                        <strong className="theme-page-text col font-18-22 fw-600">
                        {stringToPrice(product.regular_price)}
                        </strong>
                      ) : (
                        <>
                          <strong className="theme-page-text col font-18-22 fw-600">
                            {stringToPrice(product.sale_price)}
                          </strong>
                          <del className="col font-12-16 fw-400 text-gray-4">
                            {stringToPrice(product.regular_price)}
                          </del>
                        </>
                      )
                      
                    }
                  </>
                )}
                <span className={expirationClassName + " mb-0 col font-12"}>
                  {expirationMessage}
                </span>
              </div>
              <div className="row my-4 d-none d-md-flex">
                <div className="col-4">
                  <Button
                    className="w-100 fw-300"
                    onClick={addToCart}
                    disabled={!product.purchasable}
                  >
                    Aggiungi
                  </Button>
                </div>
                <div className="col-1 d-none d-md-flex">
                  <Button
                    className="w-100"
                    variant="contained"
                    onClick={toggleWishlist}
                    sx={{
                      border: 1,
                      borderColor: product.wishlist
                        ? "productFavoriteButton.contrastText"
                        : "productFavoriteButtonUnselected.main",
                    }}
                    color="productFavoriteButton"
                  >
                    {product.wishlist ? (
                      <Favorite />
                    ) : (
                      <FavoriteBorder color="productFavoriteButtonUnselected" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row d-none d-md-flex">{attributesHtml}</div>
            </div>
          </div>
          <div className="theme-box row d-md-none">{attributesHtml}</div>
        </div>
      </div>

      <div className="container-fluid fixed-bottom shadow z-1">
        <div className="row justify-content-center align-items-center d-md-none mobile-sticky-product-block theme-header">
          <div className="col-7">
            {(!Config.priceOnlyForLoggedIn || (Config.priceOnlyForLoggedIn && isLoggedIn())) &&  (
                  <>
                    {
                      (product.sale_price === "")? (
                        <strong className="theme-page-text col pr-0 font-18-27 theme-header-text">
                        {stringToPrice(product.regular_price)}
                        </strong>
                      ) : (
                        <>
                          <strong className="theme-page-text col pr-0 font-18-27 theme-header-text">
                            {stringToPrice(product.sale_price)}
                          </strong>
                          <del className="text-muted col pl-0 font-14 theme-footer-text">
                            {stringToPrice(product.regular_price)}
                          </del>
                        </>
                      )
                      
                    }
                  </>
                )}
                <div className="row justify-content-center align-items-center">
                  <p className={expirationClassName + " mb-0 col font-12-14"}>
                    {expirationMessage}
                  </p>
                </div>
          </div>
          <div className="col-5">
            <Button
              onClick={addToCart}
              className="w-100"
              variant="contained"
              color="button"
            >
              Aggiungi
            </Button>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-5 d-none d-md-flex">
        <div className="col-10">
          <hr className="theme-page-hr" />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-10 col-12">
          <div className="row">{additionalDescriptionsHtml}</div>
        </div>
      </div>

      {Config.activeTheme === "additional" ? (
        <ExpiringOffersSection expiringOffers={expiringOffers} />
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-md-10 col-12">
              <ProductCarousel title="CORRELATI" items={correlatedOffers} />
            </div>
          </div>
          { (Object.keys(expiringOffers).length > 0)? (
          <div className="row justify-content-center mb-5">
            <div className="col-md-10 col-12">
              <ProductCarousel
                title="OFFERTE IN SCADENZA"
                items={expiringOffers}
              />
            </div>
          </div>
          ) : (<div></div>)}
        </>
      )}
    </>
  );
}
