import { useState, useEffect } from "react";
import SectionTitle from "../components/layout/section-title";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import { path as loginPath } from "./login-page";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { registerUser } from "../service/user-service";
import LoadingScreen from "../components/layout/loading-screen";
import { Helmet } from "react-helmet";
import { formatPageTitle } from "../service/formatting-service";

export const path = "/pages/signup";
export default function SignUpPage({ setCloseableOnMobile }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [marketing, setMarketing] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  useEffect(() => setCloseableOnMobile(true), [setCloseableOnMobile]);

  if (loading) {
    return <LoadingScreen className="h-100" />;
  }

  const onRegisterFormSubmit = async (event) => {
    event.preventDefault();

    if (password && password !== confirmPassword) {
      setPasswordMismatchError(true);
      return;
    }
    setPasswordMismatchError(false);
    setLoading(true);
    const response = await registerUser(
      email,
      password,
      name,
      surname,
      phone,
      privacy,
      marketing,
      newsletter
    );
    setLoading(false);

    if (response.message) {
      setErrorMessage(response.message);
    } else {
      setErrorMessage("");
      history.push(loginPath);
    }
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle("Registrati")}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content="Registrati" />
        <meta property="og:title" content={formatPageTitle("Registrati")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Registrati" />
      </Helmet>
      <SectionTitle
        title="Registrati"
        subtitle="Registrati per godere di vantaggi esclusivi"
      />
      <div className="row justify-content-center">
        <form className="col mb-5" onSubmit={onRegisterFormSubmit}>
          <div className="row justify-content-center">
            <TextField
              label="Nome"
              variant="standard"
              className="mb-4 col-md-6 col-10"
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
              color="textInputMobileAccent"
            />
          </div>
          <div className="row justify-content-center">
            <TextField
              label="Cognome"
              variant="standard"
              className="mb-4 col-md-6 col-10"
              type="text"
              onChange={(e) => setSurname(e.target.value)}
              value={surname}
              color="textInputMobileAccent"
            />
          </div>
          <div className="row justify-content-center">
            <TextField
              label="Telefono"
              variant="standard"
              className="mb-4 col-md-6 col-10"
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              color="textInputMobileAccent"
            />
          </div>
          <div className="row justify-content-center">
            <TextField
              label="Email"
              variant="standard"
              className="mb-4 col-md-6 col-10"
              type="email"
              placeholder="mario.rossi@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              color="textInputMobileAccent"
            />
          </div>
          <div className="row justify-content-center">
            <TextField
              label="Password"
              variant="standard"
              type="password"
              className="mb-4 col-md-6 col-10"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              error={passwordMismatchError}
              required
              color="textInputMobileAccent"
            />
          </div>
          <div className="row justify-content-center">
            <TextField
              label="Ripeti password"
              variant="standard"
              type="password"
              className="mb-4 col-md-6 col-10"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              error={passwordMismatchError}
              helperText={
                passwordMismatchError
                  ? "Le password inserite non coincidono"
                  : ""
              }
              required
              color="textInputMobileAccent"
            />
          </div>
          <div className="row justify-content-center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={privacy}
                  onChange={(e) => setPrivacy(e.target.checked)}
                  required
                  color="checkboxSinglePageMobileOnly"
                  sx={{ color: "checkboxSinglePageMobileOnly.contrastText" }}
                />
              }
              disableTypography={true}
              className="mb-0 col-md-6 text-left px-0 col-10 font-12"
              label={
                <span>
                  Ho letto e accetto{" "}
                  <u>
                    <Link className="colored-link text-gray-3 " to="/privacy">
                      privacy
                    </Link>{" "}
                  </u>
                  e{" "}
                  <u>
                    <Link
                      className="colored-link text-gray-3 "
                      to="/terms-and-conditions"
                    >
                      termini di utilizzo
                    </Link>
                  </u>
                  *
                </span>
              }
            />
          </div>
          <div className="row justify-content-center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={newsletter}
                  onChange={(e) => setNewsletter(e.target.checked)}
                  color="checkboxSinglePageMobileOnly"
                  sx={{ color: "checkboxSinglePageMobileOnly.contrastText" }}
                />
              }
              disableTypography={true}
              className="mb-0 col-md-6 text-left px-0 col-10 font-12 align-bottom"
              label="Voglio essere iscritto alla newsletter per rimanere sempre aggiornato"
            />
          </div>
          <div className="row justify-content-center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={marketing}
                  onChange={(e) => setMarketing(e.target.checked)}
                  color="checkboxSinglePageMobileOnly"
                  sx={{ color: "checkboxSinglePageMobileOnly.contrastText" }}
                />
              }
              disableTypography={true}
              className="mb-0 col-md-6 text-left px-0 col-10 font-12"
              label="Presto il consenso per il trattamento dei dati ai fini di marketing"
            />
          </div>

          {errorMessage && (
            <div className="row justify-content-center my-4">
              <div
                className="col-12 col-md-10 text-center text-danger"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              ></div>
            </div>
          )}
          <div className="row justify-content-center mt-4">
            <Button
              className="col-md-6 col-10"
              type="submit"
              variant="contained"
              color="button"
            >
              Registrati
            </Button>
          </div>
          <div className="row justify-content-center mb-4 mt-3">
            <sub className="col-md-6 text-right px-0 col-10">
              Hai già un account?&nbsp;
              <u>
                <Link
                  className="link-unstyled theme-color-primary"
                  to={loginPath}
                >
                  Accedi
                </Link>
              </u>
            </sub>
          </div>
        </form>
      </div>
    </>
  );
}
