import {
  Button,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Link } from "react-router-dom";
import { stringToPrice } from "../service/formatting-service";
import { path as paymentOnePath } from "../pages/payment/payment1-page";
import paymentCards from "../assets/payment-cards.png";
import Config from "../config.json";
import { useHistory } from "react-router-dom";

export default function PaymentBox({ cart, title, showPayment, className, shipping }) {
  

  const articlesNumber = cart?.item_count;
  const totals = cart?.totals;
  const discount = stringToPrice(totals?.discount_total);
  const subtotal = stringToPrice(totals?.subtotal);
  //const shippingTotal = stringToPrice(totals?.shipping_total);
  const total = stringToPrice(totals?.total);
  const history = useHistory();

  const onBuyClick = () => {
    history.push(paymentOnePath);
  };

  const formattedShippingPrice = shipping? stringToPrice(totals.shipping_total) : "Da definire";
  

  return (
    <div className={"theme-box " + (className ?? "")}>
      <div className="row">
        <h4
          className={
            "fw-600 theme-box-title gray-box-header col" +
            (Config.themes[Config.activeTheme].boxTitleForceUppercase
              ? " text-uppercase"
              : "")
          }
        >
          {title}
        </h4>
      </div>
      {showPayment && (
        <>
          <div className="row align-items-center">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="metodo di pagamento"
                defaultValue="credit-card"
                name="radio-buttons-group"
                className="col"
              >
                <FormControlLabel
                  value="credit-card"
                  control={<Radio color="radio" />}
                  label="Carta di credito"
                  className="theme-page-text gray-box-text mb-0"
                />
              </RadioGroup>
            </FormControl>
            <div className="col text-right">
              <img src={paymentCards} alt="Carta di credito" width="80" />
            </div>
          </div>
          <hr className="my-2" />
        </>
      )}
      <div className="row justify-content-between align-items-center">
        <p className="col theme-page-text gray-box-text text-left">
          Totale articolo/i
        </p>
        <p className="col theme-page-text fw-600 gray-box-text text-right">
          {subtotal}
        </p>
      </div>
      <div className="row justify-content-between align-items-center">
        <p className="col theme-page-text gray-box-text text-left">Sconto</p>
        <p className="col theme-page-text gray-box-text text-right">
          {discount}
        </p>
      </div>
      <hr className="my-2" />
      <div className="row justify-content-between align-items-center">
        <p className="col theme-page-text gray-box-text text-left">Subtotale</p>
        <p className="col theme-page-text gray-box-subtext text-right">
          {subtotal}
        </p>
      </div>
      {totals && totals.shipping_total && (
      <div className="row justify-content-between align-items-center">
        <p className="col theme-page-text gray-box-text text-left">
          Spedizione/consegna
        </p>
        <p className="col theme-page-text gray-box-text text-right">
          {formattedShippingPrice}
        </p>
      </div>
      )}
      <div className="row justify-content-between align-items-center">
        <p className="col theme-page-text gray-box-text text-left">
          Totale ({articlesNumber} articoli)
        </p>
        <p className="col theme-page-text fw-600 gray-box-text text-right">
          {total}
        </p>
      </div>
      <hr className="my-2" />
      <div className="row theme-page-text align-items-center">
        <p className="col theme-page-text gray-box-subtext text-left">
          IVA inclusa (dove applicabile)
        </p>
      </div>
      {showPayment && (
        <>
          <div className="row theme-page-text mb-2 justify-content-center">
            <div className="col">
              <Button
                className="w-100"
                variant="contained"
                color="button"
                disabled={!articlesNumber}
                onClick={onBuyClick}
              >
                Acquista
              </Button>
            </div>
          </div>
          <div className="row align-items-center">
            <p className="col theme-page-text gray-box-subtext text-left">
              leggi le condizioni e i{" "}
              <u>
                <Link
                  className="link-unstyled theme-page-text"
                  to="/pages/content/termini-e-condizioni"
                >
                  termini di utilizzo
                </Link>
              </u>{" "}
              del servizio
            </p>
          </div>
        </>
      )}
    </div>
  );
}
