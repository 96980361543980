/*import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";*/
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { stringToPrice } from "../service/formatting-service";
import TextField from "@mui/material/TextField";

export default function CartRow({
  item_key,
  name,
  price,
  quantity,
  featured_image,
  onRowDelete,
  onQuantityUpdate,
  shipping,
  totals
}) {
  const onDeleteRowClicked = () => onRowDelete(item_key);
  const onQuantityChange = (e) => onQuantityUpdate(item_key, e.target);

  //const formattedPrice = stringToPrice(price);
  const formattedPrice = stringToPrice(totals.subtotal);
  const formattedShippingPrice = shipping? stringToPrice(totals.shipping_total) : "Da definire";

  /*
  const selectOptions = [...Array(5).keys()]
    .map((i) => i + Math.max(1, quantity.value - 2))
    .map((i) => (
      <MenuItem value={i} key={i}>
        {i}
      </MenuItem>
    ));
      */

  return (
    <div className="row mb-4">
      <div className="col-3 col-md-2">
        <img src={featured_image} alt={name} className="mw-100 mh-100" />
      </div>
      <div className="col-9">
        <div className="row theme-page-text align-items-center justify-content-start">
          <div className="col-md-5 col-12 text-left font-14-16">{name}</div>
          <div className="col-md-4 col-6 order-2 order-md-1 text-md-center text-left">
            {/*}
            <Select
              value={quantity.value}
              onChange={onQuantityChange}
              variant="standard"
              className="z-1"
              disableUnderline
            >
              {selectOptions}
            </Select>
          */}
          <TextField
          InputProps={{ inputProps: { min: 1 } }}
          defaultValue={quantity.value}
          onBlur={onQuantityChange}
          variant="standard"
          className="col-2 col-md-4 font-12"
          type="number"
          color="textInput"
          fontSize="0.6em"
        />
          </div>
          <div className="col-md-3 col-6 order-1 order-md-2 text-md-right text-left font-16">
            <strong className="align-middle">{formattedPrice}</strong>
          </div>
        </div>
        <div className="row mt-2 align-items-center">
          <div className="col-10 text-left font-12">
            <span className="text-muted align-middle">Spedizione:</span>{" "}
            <span className="theme-page-text align-middle">
              {formattedShippingPrice}
            </span>
          </div>
          <div className="col-2 text-md-right text-center">
            <DeleteForeverOutlinedIcon
              onClick={onDeleteRowClicked}
              className="theme-color-secondary cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
